import Vue from 'vue'
import Vuex from 'vuex'
import tokens from "@/store/modules/tokens";
import update_favourite_places from "@/store/modules/update_favourite_places";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tokens,
        update_favourite_places
    }
})
