<template>
    <v-app>
        <v-app-bar class="header" app color="primary" dark>
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <router-link to="/" class="logo-link">
                <img src="@/assets/white_logo.svg" alt="moveapp logo"/>
            </router-link>

            <v-spacer/>

            <v-menu offset-y v-if="isAuth">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" style="margin-right: 0">
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/profile">
                        <v-list-item-title>Профиль</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout_handler">
                        <v-list-item-title>Выход</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <router-link v-else to="/login">
                <v-btn icon>
                    <v-icon>mdi-login-variant</v-icon>
                </v-btn>
            </router-link>

        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
            class="navigation-drawer"
        >
            <v-list nav dense>
                <v-list-item-group v-model="group" color="primary">
                    <router-link to="/"
                    >
                        <v-list-item>
                            <v-list-item-title>Главная</v-list-item-title>
                        </v-list-item>
                    </router-link
                    >

                    <router-link to="/create"
                    >
                        <v-list-item>
                            <v-list-item-title
                            >Создать маршрут
                            </v-list-item-title
                            >
                        </v-list-item>
                    </router-link
                    >

                    <router-link to="/popular"
                    >
                        <v-list-item>
                            <v-list-item-title
                            >Популярные маршруты
                            </v-list-item-title
                            >
                        </v-list-item>
                    </router-link
                    >

                    <router-link to="/places"
                    >
                        <v-list-item>
                            <v-list-item-title
                            >Интересные места
                            </v-list-item-title
                            >
                        </v-list-item>
                    </router-link
                    >
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view/>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import {mapGetters, mapMutations} from "vuex";
import api from "@/utils/api";
import toast from "@/utils/toast";

export default {
    components: {
        Footer,
    },

    data: () => ({
        drawer: false,
        group: null,
    }),

    computed: {
        ...mapGetters(['isAuth', 'getAccessToken'])
    },

    methods: {
        ...mapMutations(['logout']),
        logout_handler() {
            this.logout();
            api.post('/v1/auth/logout/').catch((error) => {
                toast.undefined_error()
            })
            this.$router.push('/').catch(() => {
            });
        },
    },

    watch: {
        group() {
            this.drawer = false;
        }
    },

    mounted() {
    },
};
</script>

<style scoped>
a {
    text-decoration: none !important;
}

.logo-link {
    display: flex;
}

.logo-link > img {
    height: 28px;
    margin-left: 10px;
}

.navigation-drawer {
    font-weight: 600;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

body {
    width: 100%;
}

body,
h1,
#app {
    font-family: "Montserrat", sans-serif !important;
}
</style>
