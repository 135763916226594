export default {
    state: {
        need_update: false
    },
    mutations: {
        setNeedUpdate(state, value) {
            state.need_update = value
        }
    },
    getters: {
        isNeedUpdate(state) {
            return state.need_update
        }
    },
}
