import axios from "axios";
import store from "@/store";

export default {
    request(method, url, data = null, params = null) {
        return axios.request(
            {
                url: url,
                method: method,
                baseURL: process.env.VUE_APP_API_URL,
                data: data,
                params: params,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    ...store.getters.isAuth ? {Authorization: `Bearer ${store.getters.getAccessToken}`} : {}
                }
            }
        ).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            })
        }).catch((error) => {
            if (store.getters.isAuth && error.response?.status === 403 && error.response.data?.detail === 'Invalid token') {
                return axios.post(process.env.VUE_APP_API_URL + '/v1/auth/refresh/', null, {
                    withCredentials: true
                }).then((response) => {
                    store.commit('setAccessToken', response.data.access_token);

                    return new Promise((resolve, reject) => {
                        resolve(this.request(method, url, data));
                    })

                }).catch((error) => {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    })
                })
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                })
            }
        })
    },

    get(url, params=null) {
        return this.request('GET', url, null, params);
    },
    post(url, data = null) {
        return this.request('POST', url, data);
    },
    patch(url, data = null) {
        return this.request('PATCH', url, data);
    },
    delete(url, data = null) {
        return this.request('DELETE', url, data);
    }
}