export default {
    state: {
        access_token: localStorage.getItem('access_token'),
    },
    mutations: {
        setAccessToken(state, access_token) {
            state.access_token = access_token
            localStorage.setItem('access_token', access_token)
        },
        logout(state) {
            state.access_token = null
            localStorage.removeItem('access_token')
        }
    },
    getters: {
        getAccessToken(state) {
            return state.access_token
        },
        isAuth(state) {
            return Boolean(state.access_token || false)
        }
    },
}
