import Vue from "vue";

export default {
    toast(type, message) {
        let data = {
            position: "top-right",
            timeout: 5000,
            closeOnClick: false,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
        }

        if (type === 'error') {
            Vue.$toast.error(message, data)
        }

        if (type === 'warning') {
            Vue.$toast.warning(message, data)
        }
    },

    error(message) {
        this.toast('error', message)
    },
    warning(message) {
        this.toast('warning', message)
    },
    undefined_error() {
        this.toast('warning', 'Произошла ошибка! Попробуйте позже.')
    },
}